html,
body {
  padding: 0;
  margin: 0;
  font-family: Barlow, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.lazy-load-image-background.opacity {
  opacity: 0.3;
}

.lazy-load-image-background.opacity.lazy-load-image-loaded {
  opacity: 1;
  transition: opacity 0.3s;
}
